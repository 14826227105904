//
// Print
// --------------------------------------------------

@media print {
  #page-container,
  #main-container {
    padding: 0 !important;
  }

  #page-overlay,
  #page-loader,
  #page-header,
  #page-footer,
  #sidebar,
  #side-overlay,
  .block-options {
    display: none !important;
  }

  .block {
    box-shadow: none !important;
  }
}
