//
// Hero
// --------------------------------------------------

.hero {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  min-height: 500px;

  &.hero-sm {
    min-height: 300px;
  }

  &.hero-lg {
    min-height: 800px;
  }

  &-inner {
    flex: 0 0 auto;
    width: 100%;
  }

  &-meta {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem 0;
    text-align: center;
  }
}

.hero-static {
  min-height: 100vh;
}
