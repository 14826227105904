//
// Custom Main
//
// Add your own styles or override existing ones
// --------------------------------------------------

// Global
.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.z-30 {
    z-index: 30;
}

.z-40 {
    z-index: 40;
}

.z-50 {
    z-index: 50;
}

.cursor-pointer {
    cursor: move;
}

.cursor-move {
    cursor: move;
}

picture,
figure {
    position: relative;
    display: inline-block;
    @include boxShadow();
    border-radius: 6px;
    overflow: hidden;

    img {
        user-drag: none;
        -webkit-user-drag: none;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        max-width: 100%;
        @include media-breakpoint-only(xs) {
            height: 100%;
        }
    }

    figcaption {
        @include textShadow();
        @include transition();
        color: $white;
        background-color: rgba(0, 0, 0, 0.3);
        padding: 0.5rem 1rem;
        position: absolute;
        font-size: $font-size-sm;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        @include media-breakpoint-between(md, xxl) {
            border-radius: 6px;
            width: 98%;
            bottom: 0.5rem;
        }
        @include media-breakpoint-only(xs) {
            border-radius: 0px;
            width: 94%;
            bottom: 10px;
            font-size: 0.8rem;
        }
    }
}

.content-area {
    p {
        margin-bottom: 1rem;
    }

    ul,
    ol {
        padding-left: 15px;

        li {
            margin-bottom: 1rem;
            font-weight: $font-weight-light;
        }
    }

    blockquote {
        padding: 1rem 0 1rem 1.3rem;
        font-weight: 2rem;
        background-color: $accordion-bg;
        border-left: 3px solid $body-color;

        p {
            margin-bottom: 0;
        }
    }
}

//
// Dark Mode
//
// Add your own dark mode styles or override existing ones
// --------------------------------------------------

.dark-mode {
    .content-area {
        blockquote {
            border-left: 3px solid $white;
            background-color: $dark;
        }
    }
}

.body-dark-mode {
    .auth-footer {
        p {
            color: $white !important;
        }
    }
}


.btn {
    .si-arrow-right {
        top: 0px;
        font-size: $font-size-sm;
    }
}

