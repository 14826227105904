//
// jVectorMap
//
// Overwrite/Extend styles
// --------------------------------------------------

.jvectormap-tip {
  padding: 0.375rem 0.5rem;
  font-family: $font-family-base;
  background: $primary-dark;
  border: none;
  border-radius: 0;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  left: 1rem;
  padding: 0.25rem;
  line-height: 1rem;
  background: $primary-dark;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 1rem;
  height: 1rem;
}

.jvectormap-zoomin:hover,
.jvectormap-zoomout:hover {
  opacity: 0.6;
}

.jvectormap-zoomout {
  top: 2.25rem;
}
