//
// Flatpickr
//
// Overwrite/Extend styles
// --------------------------------------------------

.flatpickr-input.form-control:disabled,
.flatpickr-input.form-control[readonly],
.input.form-control:disabled,
.input.form-control[readonly] {
    color: $input-color;
    background-color: $input-bg;
    border-color: $input-border-color;
}

.flatpickr-weekdays {
    height: 2rem;
    align-items: flex-end;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    border-color: $primary;
    background: $primary;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: $primary;
}
.flatpickr-months .flatpickr-month {
    height: 42px !important;
}
.flatpickr-current-month {
    margin-bottom: 1rem !important;
    .flatpickr-monthDropdown-months,
    input.cur-year {
        font-size: 1rem !important;
        padding: 3px !important;
        height: 27px !important;
    }
}

.body-dark-mode {
    .flatpickr-calendar {
        background-color: darken($primary-darker, 4.5%) !important;
        box-shadow: 1px 0 0 $dark, -1px 0 0 $dark, 0 1px 0 $dark, 0 -1px 0 $dark,
            0 3px 13px rgb(0 0 0 / 8%) !important;
        .flatpickr-current-month .flatpickr-monthDropdown-months,
        .flatpickr-current-month input.cur-year {
            background-color: $white;
        }
        .flatpickr-months .flatpickr-prev-month,
        .flatpickr-months .flatpickr-next-month {
            color: $white;
            fill: $white;
        }
        .flatpickr-day {
            color: $white;
            &:hover,
            &:focus {
                color: $dark;
            }
        }
        .flatpickr-weekday {
            color: $white;
        }
    }
}
