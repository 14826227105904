//
// Cards
// --------------------------------------------------

.card {
    &.card-borderless {
        box-shadow: 0 1px 2px rgba(shade-color($body-bg-dark, 3%), 0.5),
            0 1px 2px rgba(shade-color($body-bg-dark, 3%), 0.5);
    }

    &.card-square {
        border-radius: 0;

        > .list-group:first-child,
        > .list-group:last-child,
        > .card-header:first-child,
        > .card-footer:last-child,
        .card-img-overlay,
        .card-img,
        .card-img-top,
        .card-img,
        .card-img-bottom {
            border-radius: 0;
        }
    }

    &.card-borderless {
        border: 0;

        > .card-header {
            border-bottom: 0;
        }

        > .card-footer {
            border-top: 0;
        }
    }
    .card-action {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 20;
        .btn-group {
            .btn:first-child {
                border-top-left-radius: 0;
            }
            .btn:last-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}

.card-action-out {
    position: absolute;
    right: 61px;
    z-index: 20;
}
