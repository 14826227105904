//
// Main Navigation
// --------------------------------------------------

.nav-main {
    @include list-unstyled;
    margin-left: -$space-side;
    margin-right: -$space-side;
}

// Headings
.nav-main-heading {
    padding: 0.375rem $space-side 0.375rem;
    font-size: 0.75rem;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    color: lighten($body-color, 25%);
}

// Default links
.nav-main-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.8rem $space-side;
    min-height: 2.5rem;
    font-size: $font-size-base;
    font-weight: $font-weight-light;
    line-height: 1.25rem;
    color: $body-color;

    .nav-main-link-icon {
        flex: 0 0 auto;
        display: inline-block;
        margin-right: 0.625rem;
        min-width: 1rem;
        text-align: center;
        color: lighten($body-color, 35%);
    }

    .nav-main-link-name {
        flex: 1 1 auto;
        display: inline-block;
        max-width: 100%;
    }

    .nav-main-link-badge {
        flex: 0 0 auto;
        display: inline-block;
        margin-left: 0.625rem;
        padding-right: 0.375rem;
        padding-left: 0.375rem;
        font-size: 0.75rem;
    }

    &:hover,
    &:focus {
        color: $body-color;
        background-color: $body-bg-light;
        outline: 0 !important;

        > .nav-main-link-icon {
            color: $black;
        }
    }

    &.active {
        color: $black;

        > .nav-main-link-icon {
            color: $black;
        }
    }

    &.nav-main-link-submenu {
        padding-right: 2rem;

        &::before {
            position: absolute;
            top: 50%;
            right: 0.625rem;
            display: block;
            margin-top: -0.5rem;
            width: 1rem;
            height: 1rem;
            line-height: 1rem;
            text-align: center;
            font-family: "Font Awesome 6 Free", "Font Awesome 6 Pro";
            font-weight: 900;
            font-size: 0.75rem;
            transition: opacity $main-nav-transition, transform $main-nav-transition;
        }

        &::before {
            content: "\f104";
            opacity: 0.4;
        }
    }
}

// Sub menus
.nav-main-submenu {
    padding-left: 1.625rem + $space-side;
    list-style: none;
    height: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.02);

    .nav-main-item {
        opacity: 0;
        transition: opacity $main-nav-transition, transform $main-nav-transition;
        transform: translateX(-0.75rem);
    }

    .nav-main-heading {
        padding-top: 1.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0;
    }

    .nav-main-link {
        margin: 0;
        padding-left: 0;
        padding-top: 0.575rem;
        padding-bottom: 0.575rem;
        min-height: 2.125rem;
        font-size: $font-size-sm;
        color: lighten($body-color, 10%);

        &:hover,
        &:focus,
        &.active {
            color: $black;
            background-color: transparent;
        }
    }

    .nav-main-submenu {
        padding-left: 0.75rem;
    }
}

// Active sub menu
.nav-main-item.open {
    > .nav-main-link-submenu {
        color: $black;

        > .nav-main-link-icon {
            color: $black;
        }

        &::before {
            transform: rotate(-90deg);
        }
    }

    > .nav-main-submenu {
        height: auto;

        > .nav-main-item {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

.nav-main-submenu .nav-main-item.open .nav-main-link {
    background-color: transparent;
}

// Nav Main Horizontal
.nav-main-horizontal {
    margin: 0;
}

@include media-breakpoint-up(lg) {
    .nav-main-horizontal {
        display: flex;
        flex-wrap: wrap;

        // Headings
        .nav-main-heading {
            display: none;
        }

        // Nav li items
        > .nav-main-item {
            position: relative;
            display: inline-block;

            &:not(:last-child) {
                margin-right: 0.25rem;
            }
        }

        // Link
        .nav-main-link-submenu::before {
            content: "\f107";
        }

        // Sub menus
        .nav-main-submenu {
            position: absolute;
            left: 0;
            width: 230px;
            padding-left: 0;
            z-index: $zindex-dropdown - 5;
            background-color: $body-bg;
            box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.08);

            &.nav-main-submenu-right {
                left: auto;
                right: 0;
            }

            .nav-main-link {
                padding-left: 1rem;
            }

            .nav-main-link-submenu::before {
                content: "\f105";
            }

            .nav-main-item.open > .nav-main-link-submenu::before {
                transform: scaleX(-1);
            }
        }

        // Active sub menu
        .nav-main-item.open {
            > .nav-main-link-submenu::before {
                transform: scaleY(-1);
            }

            > .nav-main-submenu {
                height: auto;
                margin-top: -1px;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                overflow: visible;

                > .nav-main-item {
                    transform: translateX(0);
                }
            }
        }

        // Sub menus - 2++ Level
        .nav-main-submenu .nav-main-submenu {
            top: -0.5rem;
            left: auto;
            right: -100%;
            margin-top: 0;
        }

        // Menu variations
        &.nav-main-horizontal-center {
            justify-content: center;
        }

        &.nav-main-horizontal-justify {
            > .nav-main-item {
                flex: 1 1 auto;
            }
        }

        &.nav-main-hover {
            .nav-main-item:hover {
                > .nav-main-link-submenu {
                    opacity: 1;
                    color: $black;

                    > .nav-main-link-icon {
                        color: $black;
                    }

                    &::before {
                        transform: scaleY(-1);
                    }
                }

                > .nav-main-submenu {
                    height: auto;
                    margin-top: -1px;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                    overflow: visible;

                    .nav-main-submenu {
                        margin-top: 0;
                    }

                    > .nav-main-item {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }
        }

        &.nav-main-hover .nav-main-submenu {
            .nav-main-item:hover > .nav-main-link-submenu::before {
                transform: scaleX(-1);
            }
        }
    }
}

// Dark Sidebar Variation
.nav-main-dark,
.sidebar-dark #sidebar,
.page-header-dark #page-header {
    // Headings
    .nav-main-heading {
        color: darken($body-bg-dark, 35%);
    }

    // Default links
    .nav-main-link {
        color: darken($body-bg-dark, 20%);

        > .nav-main-link-icon {
            color: rgba(darken($body-bg-dark, 20%), .5);
        }

        &:hover,
        &:focus {
            color: darken($body-bg-dark, 25%);
            background-color: rgba(0, 0, 0, .2);

            > .nav-main-link-icon {
                color: $white;
            }
        }

        &.active {
            color: $white;

            > .nav-main-link-icon {
                color: $white;
            }
        }
    }

    // Sub menus
    .nav-main-submenu {
        background-color: rgba(0, 0, 0, .15);

        .nav-main-link {
            color: darken($body-bg-dark, 30%);

            &:hover,
            &:focus,
            &.active {
                color: $white;
                background-color: transparent;
            }
        }
    }

    // Active sub menu
    .nav-main-item.open {
        > .nav-main-link-submenu,
        > .nav-main-link-submenu > .nav-main-link-icon {
            color: $white;
        }

        > .nav-main-submenu {
            background-color: rgba(0, 0, 0, .1);
        }
    }

    .nav-main-submenu .nav-main-item.open .nav-main-link {
        background-color: transparent;
    }
}

@include media-breakpoint-up(lg) {
    .nav-main-dark.nav-main-horizontal,
    .sidebar-dark #sidebar .nav-main-horizontal,
    .page-header-dark #page-header .nav-main-horizontal {
        .nav-main-submenu {
            background-color: darken($primary-darker, 3%) !important;
            box-shadow: none;
        }

        &.nav-main-hover {
            .nav-main-item:hover {
                > .nav-main-link-submenu {
                    color: $white;

                    > .nav-main-link-icon {
                        color: $white;
                    }
                }
            }

            .nav-main-submenu .nav-main-item:hover .nav-main-link {
                background-color: transparent;
            }
        }
    }
}
