//
// Pagination
// --------------------------------------------------

.page-link {
    font-weight: $font-weight-semibold;
    border-width: 0 0 $pagination-border-width 0;
    border-radius: 0 !important;
    .si {
        position: relative;
        top: 0px;
        font-size: $font-size-sm;
    }
}
