//
// Ckeditor Editor
//
// Overwrite/Extend styles
// --------------------------------------------------

.cke_chrome,
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
    border-color: $body-bg-dark !important;
}

.cke_top,
.ck.ck-toolbar {
    border-bottom-color: $body-bg-dark !important;
    background: $body-bg-light !important;
    box-shadow: none !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
    background: $body-bg-dark !important;
}

.cke_bottom {
    border-top-color: $body-bg-dark !important;
    background: $body-bg-light !important;
    box-shadow: none !important;
}

.dark-mode {
    .cke_wysiwyg_frame,
    textarea.cke_source {
        background-color: darken($primary-darker, 4.5%) !important;
        color: $white !important;
    }
}

.cke_toolbox {
    display: flex;
    overflow-x: auto;
    .cke_toolbar {
        a.cke_button {
            display: flex;
        }
    }
    .cke_toolgroup {
        display: flex;
    }
}
