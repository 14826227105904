//
// Borders
// --------------------------------------------------

.border-white-op {
  border-color: rgba($white, 0.1) !important;
}
.border-black-op {
  border-color: rgba($black, 0.1) !important;
}
